<template>
  <div :class="`m-3 p-2 wrapper  wrapper--${bu}`">
    <h3 class="text-center">{{ title }}</h3>
    <Loading v-if="loading" />
    <table v-if="!loading" class="w-100 table--default" :class="`table__border--${bu}`">
      <thead :class="`table__head--${bu}`">
        <tr>
          <th class="table__head--text">Nummer</th>
          <th class="table__head--text">Soort</th>
          <th class="table__head--text">Merk & Type</th>
          <th class="table__head--text">Jaar</th>
          <th class="table__data--right table__head--text">Stadagen</th>
          <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__head--text">Inkopen</th>
          <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__head--text">Kosten</th>
          <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__head--text">Vraagprijs</th>
          <th class="table__data--right table__head--text">Afschrijving</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, key) in stock_position_details"
          :key="key"
          :class="`table__row--color`"
        >
          <td class="table__cell--default">
            <ImageHover :itemnummer="item.nummer" :bu="bu" />
          </td>
          <td class="table__cell--default">{{ item.soort }}</td>
          <td class="table__cell--default">{{ item.merk }}</td>
          <td class="table__cell--default">{{ item.regjaar }}</td>
          <td class="table__data--right table__cell--default">{{ item.stadagen }}</td>
          <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__cell--default">€ {{ item.inkoopbedrag }}</td>
          <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__cell--default">€ {{ item.kostenbedrag }}</td>
          <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__cell--default">€ {{ item.vraagprijs }}</td>
          <td class="table__data--right table__cell--default">€ {{ item.Afschrijving }}</td>
        </tr>
      </tbody>
      <tfoot class="table__foot">
        <th class="table__foot--padding">Totaal:</th>
        <th class="table__foot--padding"></th>
        <th class="table__foot--padding"></th>
        <th class="table__foot--padding"></th>
        <th class="table__data--right table__foot--padding">{{ stock_position_details_sum.stadagen }}</th>
        <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__foot--padding">€ {{ stock_position_details_sum.inkopen }}</th>
        <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__foot--padding">€ {{ stock_position_details_sum.kosten }}</th>
        <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__foot--padding">€ {{ stock_position_details_sum.vraagprijs }}</th>
        <th class="table__data--right table__foot--padding">€ {{ stock_position_details_sum.afschrijving }}</th>
      </tfoot>
    </table>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import ImageHover from "@/components/ImageHover.vue";


export default {
  props: ["bu", "title"],
  components: { Loading, ImageHover },
  data: () => ({
    stock_position_details: null,
    stock_position_details_sum: null,
    loading: true,
  }),
  created() {
    this.getData(this.bu, this.title);
  },
  methods: {
    getData(bu, title) {
      request(`stock-position-kind-details/${bu}/${title}`, "GET").then(
        ({ stock_position_details, stock_position_details_sum }) => {
          this.stock_position_details = stock_position_details;
          this.stock_position_details_sum = stock_position_details_sum;
          this.loading = false;
        }
      );
    },
    
  },
};
</script>
